export default {
  lineColor: '線',
  lineColorFull: '線の色',
  fillColor: '塗',
  fillColorFull: '塗りつぶしの色',
  alpha: 'A',
  alphaFull: 'アルファ',
  lineWidth: '幅',
  lineWidthFull: '線の幅',
  arrowLength: '矢',
  arrowLengthFull: '矢印の長さ',
  eraserWidth: '幅',
  eraserWidthFull: '消しゴムの幅',
  textColor: '色',
  textColorFull: 'テキストの色',
  fontSize: '級',
  fontSizeFull: 'フォントサイズ',
  fontStrokeSize: '幅',
  fontStrokeSizeFull: 'ストロークの幅',
  fontStyle: '書式',
  fontStyleFull: 'フォントスタイル',
  fontName: '書体',
  fontNameFull: 'フォント',
  textStrokeColor: 'SC',
  textStrokeColorFull: 'ストロークの色',
  apply: '適用',
  cancel: 'キャンセル',
  close: '閉じる',
  clear: 'クリア',
  width: '幅',
  height: '高さ',
  keepRatio: '幅／高さのアスペクト比を保つ',
  fillPageWith: '背景色でページを塗りつぶす',
  pixelSize: 'モザ',
  pixelSizeFull: 'モザイクサイズ',
  resizeScale: 'スケール',
  resizeResize: 'リサイズ',
  backgroundColor: 'ページの背景色',
  pixelizePixelSize: 'モザイクのサイズ',
  language: '言語',
  wrongPixelSizeValue:
    'モザイクのサイズが正しくありません。' +
    '次のように指定していください：\n' +
    ' "20%"（モザイクサイズは選択範囲の1/5になります）\n' +
    ' "4"（4pxを意味します）\n',
  tools: {
    crop: '切り抜き',
    pixelize: 'モザイク',
    rect: '四角形を描く',
    ellipse: '円を描く',
    line: '線を描く',
    arrow: '矢印を描く',
    rotate: '画像を回転',
    save: '画像を保存',
    load: '画像を読み込み',
    text: 'テキストを配置',
    brush: 'ブラシ',
    resize: 'リサイズまたはスケール',
    open: '画像を開く',
    select: '範囲選択',
    close: 'Painterroを閉じる',
    eraser: '消しゴム',
    settings: '設定',
    undo: '元に戻す',
    redo: 'やり直す',
  },
  pasteOptions: {
    fit: 'すべてを置き換え',
    extend_down: '下に拡張',
    extend_right: '右に拡張',
    extend: '拡張',
    over: '重ねて貼り付け',
    how_to_paste: 'どう貼り付ける？',
  },
};
