// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.ImportLayer {
    padding: 0 0.5em;
}

div.ImportLayer div.importlayer-input-fields {
    display: flex;
}

div.ImportLayer div.importlayer-input-fields > select {
    flex: 0 0 auto;
    margin-right: -1px;
}

div.ImportLayer div.importlayer-input-fields > div {
    flex: 1 1 auto;
}

div.ImportLayer button.importlayer-addbutton {
    width: 100%;
    margin: 0.5em 0;
}

div.ImportLayer button.importlayer-addbutton > div.spinner {
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.25em;
    display: inline-block;
    flex: 0 0 auto;
}

div.ImportLayer div.layer-catalog-widget {
    max-height: 13em;
}
`, "",{"version":3,"sources":["webpack://./qwc2/components/style/ImportLayer.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,oBAAoB;IACpB,qBAAqB;IACrB,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":["div.ImportLayer {\n    padding: 0 0.5em;\n}\n\ndiv.ImportLayer div.importlayer-input-fields {\n    display: flex;\n}\n\ndiv.ImportLayer div.importlayer-input-fields > select {\n    flex: 0 0 auto;\n    margin-right: -1px;\n}\n\ndiv.ImportLayer div.importlayer-input-fields > div {\n    flex: 1 1 auto;\n}\n\ndiv.ImportLayer button.importlayer-addbutton {\n    width: 100%;\n    margin: 0.5em 0;\n}\n\ndiv.ImportLayer button.importlayer-addbutton > div.spinner {\n    width: 1.5em;\n    height: 1.5em;\n    margin-right: 0.25em;\n    display: inline-block;\n    flex: 0 0 auto;\n}\n\ndiv.ImportLayer div.layer-catalog-widget {\n    max-height: 13em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
