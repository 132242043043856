// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.cyclomedia-body {
    height: calc(100% + 0.5em);
    position: relative;
    display: flex;
    flex-direction: column;
    margin: -0.25em;
}

div.cyclomedia-body-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(200, 200, 200, 0.9);
    z-index: 2;
}

div.cyclomedia-body-overlay > div.spinner {
    width: 4em;
    height: 4em;
    margin-right: 1em;
}

div.cyclomedia-scale-hint {
    flex: 0 0 auto;
    background-color: #8f8fff;
    color: white;
    font-weight: bold;
    padding: 0.25em;
    text-align: center;
    z-index: 3;
}

iframe.cyclomedia-frame {
    width: 100%;
    flex: 1 1 auto;
    border: none;
    background-color: white;
}

div.cyclomedia-login {
    background-color: var(--container-bg-color);
    padding: 0.5em;
    border: 1px solid var(--border-color);
    width: 90%;
}

div.cyclomedia-login table {
    width: 100%
}

div.cyclomedia-login table input {
    width: 100%;
}

div.cyclomedia-login button {
    width: 100%;
}

td.cyclomedia-login-message {
    font-style: italic;
}`, "",{"version":3,"sources":["webpack://./qwc2/plugins/style/Cyclomedia.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,OAAO;IACP,QAAQ;IACR,MAAM;IACN,SAAS;IACT,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,0CAA0C;IAC1C,UAAU;AACd;;AAEA;IACI,UAAU;IACV,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,yBAAyB;IACzB,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,WAAW;IACX,cAAc;IACd,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;IACI,2CAA2C;IAC3C,cAAc;IACd,qCAAqC;IACrC,UAAU;AACd;;AAEA;IACI;AACJ;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":["div.cyclomedia-body {\n    height: calc(100% + 0.5em);\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    margin: -0.25em;\n}\n\ndiv.cyclomedia-body-overlay {\n    position: absolute;\n    left: 0;\n    right: 0;\n    top: 0;\n    bottom: 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: rgba(200, 200, 200, 0.9);\n    z-index: 2;\n}\n\ndiv.cyclomedia-body-overlay > div.spinner {\n    width: 4em;\n    height: 4em;\n    margin-right: 1em;\n}\n\ndiv.cyclomedia-scale-hint {\n    flex: 0 0 auto;\n    background-color: #8f8fff;\n    color: white;\n    font-weight: bold;\n    padding: 0.25em;\n    text-align: center;\n    z-index: 3;\n}\n\niframe.cyclomedia-frame {\n    width: 100%;\n    flex: 1 1 auto;\n    border: none;\n    background-color: white;\n}\n\ndiv.cyclomedia-login {\n    background-color: var(--container-bg-color);\n    padding: 0.5em;\n    border: 1px solid var(--border-color);\n    width: 90%;\n}\n\ndiv.cyclomedia-login table {\n    width: 100%\n}\n\ndiv.cyclomedia-login table input {\n    width: 100%;\n}\n\ndiv.cyclomedia-login button {\n    width: 100%;\n}\n\ntd.cyclomedia-login-message {\n    font-style: italic;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
