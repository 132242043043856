// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon {
    display: inline-block;
}

.icon:before {
    display: flex;
    align-items: center;
    justify-content: center;
}

img.icon {
    vertical-align: top;
}

span.icon_large:before {
    font-size: 150%;
}

span.icon_xlarge:before {
    font-size: 175%;
}

.icon_clickable {
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./qwc2/components/style/Icon.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".icon {\n    display: inline-block;\n}\n\n.icon:before {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\nimg.icon {\n    vertical-align: top;\n}\n\nspan.icon_large:before {\n    font-size: 150%;\n}\n\nspan.icon_xlarge:before {\n    font-size: 175%;\n}\n\n.icon_clickable {\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
