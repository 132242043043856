// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.EditableSelect {
    position: relative;
}

div.editable-select-inputcontainer {
    width: 100%;    
}


div.editable-select-dropdown {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    border: 1px solid var(--border-color);
    max-height: 8em;
    overflow-y: auto;
    background-color: var(--input-bg-color);
}

div.editable-select-dropdown > div {
    padding: 0.25em;
}

div.editable-select-dropdown > div:hover {
    background-color: var(--list-item-bg-color-hover);
    color: var(--list-item-text-color-hover);
}

div.editable-select-selopt {
    position: absolute;
    display: inline-block;
    left: 0;
    right: 1em;
    top: 0;
    bottom: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    padding: 0 0.25em;
}
`, "",{"version":3,"sources":["webpack://./qwc2/components/widgets/style/EditableSelect.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;;AAGA;IACI,kBAAkB;IAClB,OAAO;IACP,QAAQ;IACR,YAAY;IACZ,qCAAqC;IACrC,eAAe;IACf,gBAAgB;IAChB,uCAAuC;AAC3C;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,iDAAiD;IACjD,wCAAwC;AAC5C;;AAEA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,OAAO;IACP,UAAU;IACV,MAAM;IACN,SAAS;IACT,oBAAoB;IACpB,aAAa;IACb,mBAAmB;IACnB,iBAAiB;AACrB","sourcesContent":["div.EditableSelect {\n    position: relative;\n}\n\ndiv.editable-select-inputcontainer {\n    width: 100%;    \n}\n\n\ndiv.editable-select-dropdown {\n    position: absolute;\n    left: 0;\n    right: 0;\n    bottom: 100%;\n    border: 1px solid var(--border-color);\n    max-height: 8em;\n    overflow-y: auto;\n    background-color: var(--input-bg-color);\n}\n\ndiv.editable-select-dropdown > div {\n    padding: 0.25em;\n}\n\ndiv.editable-select-dropdown > div:hover {\n    background-color: var(--list-item-bg-color-hover);\n    color: var(--list-item-text-color-hover);\n}\n\ndiv.editable-select-selopt {\n    position: absolute;\n    display: inline-block;\n    left: 0;\n    right: 1em;\n    top: 0;\n    bottom: 0;\n    pointer-events: none;\n    display: flex;\n    align-items: center;\n    padding: 0 0.25em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
