// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.theme-list-window-body {
    display: flex;
    flex-direction: column;
    height: 100%;
}

h4.theme-list-window-title {
    flex: 0 0 auto;
}

div.theme-list-window-frame {
    border: 1px solid var(--border-color);
    background-color: var(--list-bg-color);
    font-size: small;
    flex: 1 1 auto;
    overflow: auto;
    margin-bottom: -1px;
}

div.layerlist-group-items {
    padding-left: 1em;
}

div.layerlist-item {
    display: flex;
    align-items: center;
    height: 1.75em;
    margin-left: 0.5em;
}

span.layerlist-item-checkbox {
    margin-right: 0.5em;
    flex: 0 0 auto;
}

span.layerlist-item-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 0 1 auto;
}

span.layerlist-item-add {
    margin-left: auto;
    margin-right: 0.5em;
    flex: 0 0 auto;
}
`, "",{"version":3,"sources":["webpack://./qwc2/components/style/ThemeLayersListWindow.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,qCAAqC;IACrC,sCAAsC;IACtC,gBAAgB;IAChB,cAAc;IACd,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;IACvB,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,cAAc;AAClB","sourcesContent":["div.theme-list-window-body {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n}\n\nh4.theme-list-window-title {\n    flex: 0 0 auto;\n}\n\ndiv.theme-list-window-frame {\n    border: 1px solid var(--border-color);\n    background-color: var(--list-bg-color);\n    font-size: small;\n    flex: 1 1 auto;\n    overflow: auto;\n    margin-bottom: -1px;\n}\n\ndiv.layerlist-group-items {\n    padding-left: 1em;\n}\n\ndiv.layerlist-item {\n    display: flex;\n    align-items: center;\n    height: 1.75em;\n    margin-left: 0.5em;\n}\n\nspan.layerlist-item-checkbox {\n    margin-right: 0.5em;\n    flex: 0 0 auto;\n}\n\nspan.layerlist-item-title {\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    flex: 0 1 auto;\n}\n\nspan.layerlist-item-add {\n    margin-left: auto;\n    margin-right: 0.5em;\n    flex: 0 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
