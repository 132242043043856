export default {
  lineColor: 'L',
  lineColorFull: 'Color de línia',
  fillColor: 'O',
  fillColorFull: 'Color per omplir',
  alpha: 'T',
  alphaFull: 'Transparència',
  lineWidth: 'A',
  lineWidthFull: 'Ample de línia',
  arrowLength: 'L',
  arrowLengthFull: 'Longitud de la fletxa',
  eraserWidth: 'B',
  eraserWidthFull: 'Ample de borrador',
  textColor: 'C',
  textColorFull: 'Color de texte',
  fontSize: 'L',
  fontSizeFull: 'Tamany de la lletra',
  fontStrokeSize: 'TLl',
  fontStrokeSizeFull: 'Tamany linea de la lletra',
  fontStyle: 'EL',
  fontStyleFull: 'Estil de lletra',
  fontName: 'NL',
  fontNameFull: 'Nom del tipus de lletra',
  textStrokeColor: 'CL',
  textStrokeColorFull: 'Color de lletra',
  apply: 'Aplicar',
  cancel: 'Cancel·lar',
  close: 'Tancar',
  clear: 'Netejar',
  width: 'Ample',
  height: 'Alt',
  keepRatio: 'Mantenir ratio Ample/Alt',
  fillPageWith: 'Omplir la pàgina amb el color de fons actual',
  pixelSize: 'P',
  pixelSizeFull: 'Tamany de pixel',
  resizeScale: 'Escala',
  resizeResize: 'Redimensionar',
  backgroundColor: 'Color de fons de la pàgina',
  pixelizePixelSize: 'Tamany de píxel al pixelar',
  wrongPixelSizeValue:
    'Tamany de píxel incorrecte. Pots entrar per exemple \'20%\' que significa que el tamany de píxel és 1/5 de l\'àrea seleccionada, o \'4\' significa 4 px',
  tools: {
    crop: 'Retallar imatge a l\'àrea seleccionada',
    pixelize: 'Pixelar l\'àrea seleccionada',
    rect: 'Dibuixar rectangle',
    ellipse: 'Dibuixar eclipse',
    line: 'Dibuixar línia',
    arrow: 'Dibuixa la fletxa',
    rotate: 'Rotar imatge',
    save: 'Guardar image',
    load: 'Carregar image',
    text: 'Escriure texte',
    brush: 'Pinzell',
    resize: 'Cambiar tamany o escalar',
    open: 'Obrir imatge',
    select: 'Seleció d\'àrea',
    close: 'Tancar editor',
    eraser: 'Borrador',
    settings: 'Paràmetres',
  },
  pasteOptions: {
    fit: 'Reemplaçar tot',
    extend_down: 'Extendre cap avall',
    extend_right: 'Extendre a la dreta',
    extend: 'Extendre',
    over: 'Empegar al damunt',
    how_to_paste: 'Com s\'ha d\'empegar?',
  },
};
