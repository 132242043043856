// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `iframe.windows-iframe-dialog-body {
    width: 100%;
    height: 100%;
    border: 0;
}

div.dock-window iframe.windows-iframe-dialog-body {
    height: calc(100vh - var(--topbar-height) - var(--bottombar-height));
    height: calc(var(--vh, 1vh) * 100 - var(--topbar-height) - var(--bottombar-height));
}

div.windows-notification-container {
    position: absolute;
    top: var(--topbar-height);
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    max-width: calc(100% - 0.5em);
    background-color: var(--container-bg-color);
    box-shadow: 0px 2px 4px rgba(136, 136, 136, 0.5);
    border-top: 1px solid rgba(136, 136, 136, 0.5);
    z-index: 3;
}

div.windows-notification-container > div {
    padding: 0 1.25em 0 0.25em;
    position: relative;
    border-bottom: 1px solid var(--border-color);
}

div.windows-notification-container > div > div {
    padding: 0.25em 0.5em 0.25em 0.25em;
    display: inline-block;
}

div.windows-notification-container > div > span {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0.125em 0.125em 0 0;
}

div.windows-notification-warn {
    background-color: orange;
}

div.windows-notification-error {
    background-color: red;
}
`, "",{"version":3,"sources":["webpack://./qwc2/components/style/WindowManager.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,SAAS;AACb;;AAEA;IACI,oEAAoE;IACpE,mFAAmF;AACvF;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,SAAS;IACT,2BAA2B;IAC3B,qBAAqB;IACrB,6BAA6B;IAC7B,2CAA2C;IAC3C,gDAAgD;IAChD,8CAA8C;IAC9C,UAAU;AACd;;AAEA;IACI,0BAA0B;IAC1B,kBAAkB;IAClB,4CAA4C;AAChD;;AAEA;IACI,mCAAmC;IACnC,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,MAAM;IACN,SAAS;IACT,4BAA4B;AAChC;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":["iframe.windows-iframe-dialog-body {\n    width: 100%;\n    height: 100%;\n    border: 0;\n}\n\ndiv.dock-window iframe.windows-iframe-dialog-body {\n    height: calc(100vh - var(--topbar-height) - var(--bottombar-height));\n    height: calc(var(--vh, 1vh) * 100 - var(--topbar-height) - var(--bottombar-height));\n}\n\ndiv.windows-notification-container {\n    position: absolute;\n    top: var(--topbar-height);\n    left: 50%;\n    transform: translateX(-50%);\n    display: inline-block;\n    max-width: calc(100% - 0.5em);\n    background-color: var(--container-bg-color);\n    box-shadow: 0px 2px 4px rgba(136, 136, 136, 0.5);\n    border-top: 1px solid rgba(136, 136, 136, 0.5);\n    z-index: 3;\n}\n\ndiv.windows-notification-container > div {\n    padding: 0 1.25em 0 0.25em;\n    position: relative;\n    border-bottom: 1px solid var(--border-color);\n}\n\ndiv.windows-notification-container > div > div {\n    padding: 0.25em 0.5em 0.25em 0.25em;\n    display: inline-block;\n}\n\ndiv.windows-notification-container > div > span {\n    position: absolute;\n    right: 0;\n    top: 0;\n    bottom: 0;\n    padding: 0.125em 0.125em 0 0;\n}\n\ndiv.windows-notification-warn {\n    background-color: orange;\n}\n\ndiv.windows-notification-error {\n    background-color: red;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
