// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selection-drag-box {
    border: 1px dashed #0000FF;
    background-color: rgba(255, 0, 255, 0.33);
}
`, "",{"version":3,"sources":["webpack://./qwc2/plugins/map/style/SelectionSupport.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,yCAAyC;AAC7C","sourcesContent":[".selection-drag-box {\n    border: 1px dashed #0000FF;\n    background-color: rgba(255, 0, 255, 0.33);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
