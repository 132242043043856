// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#FullScreenSwitcher {
    display: inline-block;
    height: 100%;
    cursor: pointer;
    width: 1em;
    margin-left: -1em;
}

#FullScreenSwitcher > span {
    display: inline-block;
    vertical-align: top;
    width: 0;
    height: 0;
    border-style: solid;
}

#FullScreenSwitcher > span.maximize {
    border-width: 0 1em 1em 0;
    border-color: transparent var(--panel-text-color) transparent transparent;
}

#FullScreenSwitcher > span.minimize {
    border-width: 1em 0 0 1em;
    border-color: transparent transparent transparent var(--panel-text-color);
}
`, "",{"version":3,"sources":["webpack://./qwc2/components/style/FullscreenSwitcher.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,YAAY;IACZ,eAAe;IACf,UAAU;IACV,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;IACrB,mBAAmB;IACnB,QAAQ;IACR,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,yEAAyE;AAC7E;;AAEA;IACI,yBAAyB;IACzB,yEAAyE;AAC7E","sourcesContent":["#FullScreenSwitcher {\n    display: inline-block;\n    height: 100%;\n    cursor: pointer;\n    width: 1em;\n    margin-left: -1em;\n}\n\n#FullScreenSwitcher > span {\n    display: inline-block;\n    vertical-align: top;\n    width: 0;\n    height: 0;\n    border-style: solid;\n}\n\n#FullScreenSwitcher > span.maximize {\n    border-width: 0 1em 1em 0;\n    border-color: transparent var(--panel-text-color) transparent transparent;\n}\n\n#FullScreenSwitcher > span.minimize {\n    border-width: 1em 0 0 1em;\n    border-color: transparent transparent transparent var(--panel-text-color);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
