// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.process-notifications {
    position: fixed;
    right: 0;
    top: var(--topbar-height);
    box-shadow: 0 -2px 4px rgba(136, 136, 136, 0.5);
    width: 20em;
    z-index: 1000;
}

div.process-notification {
    padding: 0 0.5em;
    border: 1px solid var(--border-color);
}

div.process-notification-pending {
    color: white;
    background-color: #8886d4;
}

div.process-notification-success {
    color: white;
    background-color: #09ac00;
}

div.process-notification-failure {
    color: white;
    background-color: #ff0000;
}

div.process-notification-head {
    display: flex;
    align-items: center;
    height: 2.5em;
}

div.process-notification-head > span.icon:first-child {
    font-size: 150%;
    margin-right: 0.25em;
}

div.process-notification-head > div.spinner {
    width: 2em;
    height: 2em;
    margin-right: 0.25em;
}

span.process-notification-label {
    flex: 1 1 auto;
}

div.process-notification-detail {
    font-style: italic;
    font-size: 90%;
    padding: 0.25em;
}
`, "",{"version":3,"sources":["webpack://./qwc2/plugins/style/ProcessNotifications.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,QAAQ;IACR,yBAAyB;IACzB,+CAA+C;IAC/C,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,qCAAqC;AACzC;;AAEA;IACI,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,oBAAoB;AACxB;;AAEA;IACI,UAAU;IACV,WAAW;IACX,oBAAoB;AACxB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,eAAe;AACnB","sourcesContent":["div.process-notifications {\n    position: fixed;\n    right: 0;\n    top: var(--topbar-height);\n    box-shadow: 0 -2px 4px rgba(136, 136, 136, 0.5);\n    width: 20em;\n    z-index: 1000;\n}\n\ndiv.process-notification {\n    padding: 0 0.5em;\n    border: 1px solid var(--border-color);\n}\n\ndiv.process-notification-pending {\n    color: white;\n    background-color: #8886d4;\n}\n\ndiv.process-notification-success {\n    color: white;\n    background-color: #09ac00;\n}\n\ndiv.process-notification-failure {\n    color: white;\n    background-color: #ff0000;\n}\n\ndiv.process-notification-head {\n    display: flex;\n    align-items: center;\n    height: 2.5em;\n}\n\ndiv.process-notification-head > span.icon:first-child {\n    font-size: 150%;\n    margin-right: 0.25em;\n}\n\ndiv.process-notification-head > div.spinner {\n    width: 2em;\n    height: 2em;\n    margin-right: 0.25em;\n}\n\nspan.process-notification-label {\n    flex: 1 1 auto;\n}\n\ndiv.process-notification-detail {\n    font-style: italic;\n    font-size: 90%;\n    padding: 0.25em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
