export default {
  lineColor: 'L',
  lineColorFull: 'Color de linea',
  fillColor: 'O',
  fillColorFull: 'Color de relleno',
  alpha: 'T',
  alphaFull: 'Transparencia',
  lineWidth: 'A',
  lineWidthFull: 'Ancho de linea',
  eraserWidth: 'B',
  eraserWidthFull: 'Ancho de borrador',
  textColor: 'C',
  textColorFull: 'Color de texto',
  fontSize: 'L',
  fontSizeFull: 'Tamaño de letra',
  fontStrokeSize: 'TLl',
  fontStrokeSizeFull: 'Tamaño de linea de letra',
  fontStyle: 'EL',
  fontStyleFull: 'Estilo de letra',
  fontName: 'NL',
  fontNameFull: 'Nombre del tipo de letra',
  textStrokeColor: 'CL',
  textStrokeColorFull: 'Color de letra',
  apply: 'Aplicar',
  cancel: 'Cancelar',
  close: 'Cerrar',
  clear: 'Limpiar',
  width: 'Ancho',
  height: 'Alto',
  keepRatio: 'Mantener ratio Ancho/Alto',
  fillPageWith: 'Rellenar la página con el color de fondo actual',
  pixelSize: 'P',
  pixelSizeFull: 'Tamaño de pixel',
  resizeScale: 'Escala',
  resizeResize: 'Redimensionar',
  backgroundColor: 'Color de fondo de la página',
  pixelizePixelSize: 'Tamaño de pixel al pixelar',
  wrongPixelSizeValue:
    'Tamaño de pixel incorrecto. Puedes entrar por ejemplo \'20%\' que significa que el tamaño de pixel es 1/5 del area seleccionada, o \'4\' significa 4 px',
  tools: {
    crop: 'Recortar imagen a la area seleccionada',
    pixelize: 'Pixelar la area seleccionada',
    rect: 'Dibujar rectángulo',
    ellipse: 'Dibujar eclipse',
    line: 'Dibujar linea',
    rotate: 'Rotar imagen',
    save: 'Guardar imagen',
    load: 'Cargar imagen',
    text: 'Escribir texto',
    brush: 'Pincel',
    resize: 'Cambiar tamaño o escalar',
    open: 'Abrir imagen',
    select: 'Selección de area',
    close: 'Cerrar editor',
    eraser: 'Borrador',
    settings: 'Parámetros',
  },
  pasteOptions: {
    fit: 'Reemplazar todo',
    extend_down: 'Extender hacia abajo',
    extend_right: 'Extender a la derecha',
    extend: 'Extender',
    over: 'Pegar encima',
    how_to_paste: 'Cómo se ha de pegar?',
  },
};
