// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#MapTipPointerBuffer {
    position: absolute;
    width: 16px;
    height: 16px;
    pointer-events: none;
}

#MapTip {
    position: absolute;
    background: var(--list-bg-color);
    box-shadow: 0px 2px 5px rgba(136, 136, 136, 0.5);
    border-radius: 0.5em;
    border: 1px solid var(--border-color);
    overflow-y: auto;
}

#MapTip > div {
    padding: 0.25em;
}

#MapTip > div:not(:last-child) {
    border-bottom: 1px solid var(--border-color);
}
`, "",{"version":3,"sources":["webpack://./qwc2/plugins/style/MapTip.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;IAClB,gCAAgC;IAChC,gDAAgD;IAChD,oBAAoB;IACpB,qCAAqC;IACrC,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,4CAA4C;AAChD","sourcesContent":["#MapTipPointerBuffer {\n    position: absolute;\n    width: 16px;\n    height: 16px;\n    pointer-events: none;\n}\n\n#MapTip {\n    position: absolute;\n    background: var(--list-bg-color);\n    box-shadow: 0px 2px 5px rgba(136, 136, 136, 0.5);\n    border-radius: 0.5em;\n    border: 1px solid var(--border-color);\n    overflow-y: auto;\n}\n\n#MapTip > div {\n    padding: 0.25em;\n}\n\n#MapTip > div:not(:last-child) {\n    border-bottom: 1px solid var(--border-color);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
