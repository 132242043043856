// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.identify-radius-controls {
    display: flex;
    flex-wrap: wrap;
}

div.identify-radius-controls > span {
    display: inline-flex;
    flex: 1 0 auto;
    align-items: center;
    justify-content: center;
    margin: 0.25em 0.25em;
}

div.identify-radius-controls span.react-numeric-input {
    width: 10em;
}
`, "",{"version":3,"sources":["webpack://./qwc2/plugins/style/Identify.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,oBAAoB;IACpB,cAAc;IACd,mBAAmB;IACnB,uBAAuB;IACvB,qBAAqB;AACzB;;AAEA;IACI,WAAW;AACf","sourcesContent":["div.identify-radius-controls {\n    display: flex;\n    flex-wrap: wrap;\n}\n\ndiv.identify-radius-controls > span {\n    display: inline-flex;\n    flex: 1 0 auto;\n    align-items: center;\n    justify-content: center;\n    margin: 0.25em 0.25em;\n}\n\ndiv.identify-radius-controls span.react-numeric-input {\n    width: 10em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
