// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button.map-button {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    position: absolute !important;
    right: 1.5em;
    background-color: var(--map-button-bg-color);
    width: 3em;
    height: 3em;
    border-radius: 1.5em;
    color: var(--map-button-text-color);
    border: none;
    box-shadow: 0px 5px 10px rgba(136, 136, 136, 0.5);
    transition: background-color 0.5s, color 0.5s;
    cursor: pointer;
}

button.map-button:hover {
    background-color: var(--map-button-hover-bg-color);
    color: var(--map-button-hover-text-color);
}

button.map-button-active {
    background-color: var(--map-button-active-bg-color);
    color: var(--map-button-active-text-color);
}

button.map-button.locate-button-PERMISSION_DENIED {
    opacity: 0.7;
    cursor: default;
}

button.map-button.locate-button-PERMISSION_DENIED:hover {
    background-color: var(--map-button-bg-color);
    color: var(--map-button-text-color);
}

button.map-button.locate-button-LOCATING,
button.map-button.locate-button-ENABLED {
    background-color: var(--map-button-text-color);
    color: var(--map-button-bg-color);
}

button.map-button.locate-button-FOLLOWING {
    background-color: yellow;
    color: var(--map-button-bg-color);
}

button.map-button.locate-button-LOCATING > div.spinner {
    width: 1.5em;
    height: 1.5em;
}
`, "",{"version":3,"sources":["webpack://./qwc2/plugins/style/Buttons.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,UAAU;IACV,6BAA6B;IAC7B,YAAY;IACZ,4CAA4C;IAC5C,UAAU;IACV,WAAW;IACX,oBAAoB;IACpB,mCAAmC;IACnC,YAAY;IACZ,iDAAiD;IACjD,6CAA6C;IAC7C,eAAe;AACnB;;AAEA;IACI,kDAAkD;IAClD,yCAAyC;AAC7C;;AAEA;IACI,mDAAmD;IACnD,0CAA0C;AAC9C;;AAEA;IACI,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,4CAA4C;IAC5C,mCAAmC;AACvC;;AAEA;;IAEI,8CAA8C;IAC9C,iCAAiC;AACrC;;AAEA;IACI,wBAAwB;IACxB,iCAAiC;AACrC;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB","sourcesContent":["button.map-button {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 2;\n    position: absolute !important;\n    right: 1.5em;\n    background-color: var(--map-button-bg-color);\n    width: 3em;\n    height: 3em;\n    border-radius: 1.5em;\n    color: var(--map-button-text-color);\n    border: none;\n    box-shadow: 0px 5px 10px rgba(136, 136, 136, 0.5);\n    transition: background-color 0.5s, color 0.5s;\n    cursor: pointer;\n}\n\nbutton.map-button:hover {\n    background-color: var(--map-button-hover-bg-color);\n    color: var(--map-button-hover-text-color);\n}\n\nbutton.map-button-active {\n    background-color: var(--map-button-active-bg-color);\n    color: var(--map-button-active-text-color);\n}\n\nbutton.map-button.locate-button-PERMISSION_DENIED {\n    opacity: 0.7;\n    cursor: default;\n}\n\nbutton.map-button.locate-button-PERMISSION_DENIED:hover {\n    background-color: var(--map-button-bg-color);\n    color: var(--map-button-text-color);\n}\n\nbutton.map-button.locate-button-LOCATING,\nbutton.map-button.locate-button-ENABLED {\n    background-color: var(--map-button-text-color);\n    color: var(--map-button-bg-color);\n}\n\nbutton.map-button.locate-button-FOLLOWING {\n    background-color: yellow;\n    color: var(--map-button-bg-color);\n}\n\nbutton.map-button.locate-button-LOCATING > div.spinner {\n    width: 1.5em;\n    height: 1.5em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
