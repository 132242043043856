// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.login-user {
    position: fixed;
    right: 0.25em;
    top: var(--topbar-height);
    color: var(--panel-text-color);
    background-color: var(--panel-bg-color);
    box-shadow: 0 0 4px rgba(136, 136, 136, 0.5);
    padding: 0.25em 0em 0.125em 0em;
    display: flex;
}

div.login-user > span {
    flex: 0 0 auto;
    padding: 0 0.25em;
}
`, "",{"version":3,"sources":["webpack://./qwc2/plugins/style/LoginUser.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,yBAAyB;IACzB,8BAA8B;IAC9B,uCAAuC;IACvC,4CAA4C;IAC5C,+BAA+B;IAC/B,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB","sourcesContent":["div.login-user {\n    position: fixed;\n    right: 0.25em;\n    top: var(--topbar-height);\n    color: var(--panel-text-color);\n    background-color: var(--panel-bg-color);\n    box-shadow: 0 0 4px rgba(136, 136, 136, 0.5);\n    padding: 0.25em 0em 0.125em 0em;\n    display: flex;\n}\n\ndiv.login-user > span {\n    flex: 0 0 auto;\n    padding: 0 0.25em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
