// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.layer-info-window-body {
    display: flex;
    flex-direction: column;
    height: 100%;
}

h4.layer-info-window-title {
    flex: 0 0 auto;
}

div.layer-info-window-frame {
    border: 1px solid var(--border-color);
    background-color: var(--list-bg-color);
    font-size: small;
    flex: 1 1 auto;
    overflow: auto;
}

table.layer-info-window-table {
    width: 100%;
}

table.layer-info-window-table td {
    padding: 0.5em;
    vertical-align: top;
}

table.layer-info-window-table td:first-child {
    white-space: nowrap;
}

table.layer-info-window-table td:last-child {
    width: 99%;
}

img.layer-info-window-legend {
    box-shadow: 0px 2px 5px rgba(136, 136, 136, 0.5);
    background-color: white;
}

span.layer-info-window-coloricon {
    width: 2em;
    height: 2em;
    box-shadow: 0px 2px 5px rgba(136, 136, 136, 0.5);
    display: inline-block;
}
`, "",{"version":3,"sources":["webpack://./qwc2/components/style/LayerInfoWindow.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,qCAAqC;IACrC,sCAAsC;IACtC,gBAAgB;IAChB,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,gDAAgD;IAChD,uBAAuB;AAC3B;;AAEA;IACI,UAAU;IACV,WAAW;IACX,gDAAgD;IAChD,qBAAqB;AACzB","sourcesContent":["div.layer-info-window-body {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n}\n\nh4.layer-info-window-title {\n    flex: 0 0 auto;\n}\n\ndiv.layer-info-window-frame {\n    border: 1px solid var(--border-color);\n    background-color: var(--list-bg-color);\n    font-size: small;\n    flex: 1 1 auto;\n    overflow: auto;\n}\n\ntable.layer-info-window-table {\n    width: 100%;\n}\n\ntable.layer-info-window-table td {\n    padding: 0.5em;\n    vertical-align: top;\n}\n\ntable.layer-info-window-table td:first-child {\n    white-space: nowrap;\n}\n\ntable.layer-info-window-table td:last-child {\n    width: 99%;\n}\n\nimg.layer-info-window-legend {\n    box-shadow: 0px 2px 5px rgba(136, 136, 136, 0.5);\n    background-color: white;\n}\n\nspan.layer-info-window-coloricon {\n    width: 2em;\n    height: 2em;\n    box-shadow: 0px 2px 5px rgba(136, 136, 136, 0.5);\n    display: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
