// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.modal-dialog-container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 2em;
    background-color: rgba(127, 127, 127, 0.5);
    z-index: 202;
    display: flex;
    justify-content: center;
}

div.modal-dialog {
    height: 100%;
    background-color: var(--container-bg-color);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.75);
    display: inline-flex;
    flex-direction: column;
}

div.modal-dialog-title {
    font-weight: bold;
    color: var(--titlebar-text-color);
    background-color: var(--titlebar-bg-color);
    flex: 0 0 2.5em;
    display: flex;
    align-items: center;
    padding: 0 0.25em;
    display: flex;
    align-items: center;
}

div.modal-dialog-title span:not(.icon) {
    flex: 1 1 auto;
}

div.modal-dialog-title span.icon {
    flex: 0 0 auto;
    margin: 0 0.25em;
    font-size: large;
}

div.modal-dialog-body {
    padding: 0.25em;
    flex: 1 1 auto;
    overflow: hidden;
    text-align: center;
    position: relative;
}
`, "",{"version":3,"sources":["webpack://./qwc2/components/style/ModalDialog.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,OAAO;IACP,QAAQ;IACR,MAAM;IACN,SAAS;IACT,YAAY;IACZ,0CAA0C;IAC1C,YAAY;IACZ,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,2CAA2C;IAC3C,2CAA2C;IAC3C,oBAAoB;IACpB,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,iCAAiC;IACjC,0CAA0C;IAC1C,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":["div.modal-dialog-container {\n    position: fixed;\n    left: 0;\n    right: 0;\n    top: 0;\n    bottom: 0;\n    padding: 2em;\n    background-color: rgba(127, 127, 127, 0.5);\n    z-index: 202;\n    display: flex;\n    justify-content: center;\n}\n\ndiv.modal-dialog {\n    height: 100%;\n    background-color: var(--container-bg-color);\n    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.75);\n    display: inline-flex;\n    flex-direction: column;\n}\n\ndiv.modal-dialog-title {\n    font-weight: bold;\n    color: var(--titlebar-text-color);\n    background-color: var(--titlebar-bg-color);\n    flex: 0 0 2.5em;\n    display: flex;\n    align-items: center;\n    padding: 0 0.25em;\n    display: flex;\n    align-items: center;\n}\n\ndiv.modal-dialog-title span:not(.icon) {\n    flex: 1 1 auto;\n}\n\ndiv.modal-dialog-title span.icon {\n    flex: 0 0 auto;\n    margin: 0 0.25em;\n    font-size: large;\n}\n\ndiv.modal-dialog-body {\n    padding: 0.25em;\n    flex: 1 1 auto;\n    overflow: hidden;\n    text-align: center;\n    position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
