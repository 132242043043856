// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#RasterExport div.rasterexport-body {
    padding: 0.25em;
}

#RasterExport .rasterexport-minimize-maximize {
    margin-left: 1em;
    padding: 0.25em;
}

#RasterExport table.options-table {
    width: 100%;
}

#RasterExport table.options-table td {
    padding: 0.125em 0;
}

#RasterExport table.options-table td:first-child {
    max-width: 10em;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 0.25em;
}

#RasterExport table.options-table td:nth-child(2) {
    width: 99%;
}

#RasterExport table.options-table td:nth-child(2) > * {
    width: 100%;
}

#RasterExport table.options-table textarea {
    resize: vertical;
}

#RasterExport div.button-bar {
    margin-top: 0.5em;
}

#RasterExport div.button-bar > button {
    width: 100%;
}

#RasterExport span.rasterexport-wait {
    display: flex;
    align-items: center;
}

#RasterExport span.rasterexport-wait div.spinner {
    width: 1.25em;
    height: 1.25em;
    margin-right: 0.5em;
    flex: 0 0 auto;
}`, "",{"version":3,"sources":["webpack://./qwc2/plugins/style/RasterExport.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,uBAAuB;IACvB,qBAAqB;AACzB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,mBAAmB;IACnB,cAAc;AAClB","sourcesContent":["#RasterExport div.rasterexport-body {\n    padding: 0.25em;\n}\n\n#RasterExport .rasterexport-minimize-maximize {\n    margin-left: 1em;\n    padding: 0.25em;\n}\n\n#RasterExport table.options-table {\n    width: 100%;\n}\n\n#RasterExport table.options-table td {\n    padding: 0.125em 0;\n}\n\n#RasterExport table.options-table td:first-child {\n    max-width: 10em;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    padding-right: 0.25em;\n}\n\n#RasterExport table.options-table td:nth-child(2) {\n    width: 99%;\n}\n\n#RasterExport table.options-table td:nth-child(2) > * {\n    width: 100%;\n}\n\n#RasterExport table.options-table textarea {\n    resize: vertical;\n}\n\n#RasterExport div.button-bar {\n    margin-top: 0.5em;\n}\n\n#RasterExport div.button-bar > button {\n    width: 100%;\n}\n\n#RasterExport span.rasterexport-wait {\n    display: flex;\n    align-items: center;\n}\n\n#RasterExport span.rasterexport-wait div.spinner {\n    width: 1.25em;\n    height: 1.25em;\n    margin-right: 0.5em;\n    flex: 0 0 auto;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
