// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.ToggleSwitch {
    position: relative;
    display: flex;
    width: 100%;
    height: 2em;
    overflow: hidden;
    border: 1px solid var(--border-color);
    align-items: center;
}

div.ToggleSwitch.toggle-switch-active {
    background-color: rgb(127, 255, 127);
}

div.ToggleSwitch.toggle-switch-inactive {
    background-color: rgb(255, 127, 127);
}

div.ToggleSwitch.toggle-switch-read-only {
    background-color: var(--text-color-disabled);
}

div.ToggleSwitch > span.toggle-switch-slider {
    flex: 1 1 50%;
    background-color: var(--input-bg-color);
    border: 1px solid var(--border-color);
    border-radius: 2px;
    text-align: center;
    color: lightgray;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

div.ToggleSwitch > span.toggle-switch-slider > .icon {
    transform: rotate(90deg);
}

div.ToggleSwitch > span.toggle-switch-yes,
div.ToggleSwitch > span.toggle-switch-no {
    color: var(--input-bg-color);
    text-align: center;
    flex: 1 1 50%;
}

div.ToggleSwitch.toggle-switch-active > span.toggle-switch-no {
    display: none;
}

div.ToggleSwitch.toggle-switch-inactive > span.toggle-switch-yes {
    display: none;
}

div.ToggleSwitch > input {
    position: absolute;
    opacity: 0;
    width: 1px;
}
`, "",{"version":3,"sources":["webpack://./qwc2/components/widgets/style/ToggleSwitch.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,WAAW;IACX,WAAW;IACX,gBAAgB;IAChB,qCAAqC;IACrC,mBAAmB;AACvB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,4CAA4C;AAChD;;AAEA;IACI,aAAa;IACb,uCAAuC;IACvC,qCAAqC;IACrC,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;;IAEI,4BAA4B;IAC5B,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,UAAU;AACd","sourcesContent":["div.ToggleSwitch {\n    position: relative;\n    display: flex;\n    width: 100%;\n    height: 2em;\n    overflow: hidden;\n    border: 1px solid var(--border-color);\n    align-items: center;\n}\n\ndiv.ToggleSwitch.toggle-switch-active {\n    background-color: rgb(127, 255, 127);\n}\n\ndiv.ToggleSwitch.toggle-switch-inactive {\n    background-color: rgb(255, 127, 127);\n}\n\ndiv.ToggleSwitch.toggle-switch-read-only {\n    background-color: var(--text-color-disabled);\n}\n\ndiv.ToggleSwitch > span.toggle-switch-slider {\n    flex: 1 1 50%;\n    background-color: var(--input-bg-color);\n    border: 1px solid var(--border-color);\n    border-radius: 2px;\n    text-align: center;\n    color: lightgray;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\ndiv.ToggleSwitch > span.toggle-switch-slider > .icon {\n    transform: rotate(90deg);\n}\n\ndiv.ToggleSwitch > span.toggle-switch-yes,\ndiv.ToggleSwitch > span.toggle-switch-no {\n    color: var(--input-bg-color);\n    text-align: center;\n    flex: 1 1 50%;\n}\n\ndiv.ToggleSwitch.toggle-switch-active > span.toggle-switch-no {\n    display: none;\n}\n\ndiv.ToggleSwitch.toggle-switch-inactive > span.toggle-switch-yes {\n    display: none;\n}\n\ndiv.ToggleSwitch > input {\n    position: absolute;\n    opacity: 0;\n    width: 1px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
